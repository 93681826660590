import React from 'react';
import styles from './Loader.module.scss';

interface IStateProps {
  isFulllScreen?: boolean;
}
class Loader extends React.Component<IStateProps> {
  public render() {
    const { isFulllScreen = true } = this.props;
    return (
      <div
        className={`${styles.loaderBackdrop} ${
          isFulllScreen ? styles.fullScreen : ''
        }`}
      >
        {' '}
        <div className={styles.loaderPosition}>
          {' '}
          <div className={styles.loader} />{' '}
        </div>{' '}
      </div>
    );
  }
}
export default Loader;
