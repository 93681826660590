import { createSelector } from 'reselect';
import { AppState } from '../rootReducer';

const getUserRolesList = (state: AppState) => state.userRoles.userRolesList;
const getUserRolesLoading = (state: AppState) => state.userRoles.loading;
const getUserRolesListCount = (state: AppState) => state.userRoles.userRolesListCount;
const getSiteAdminSites = (state: AppState) => state.userRoles.siteAdminSites;

export const getUserRolesListSelector = createSelector(getUserRolesList,
    (userRolesList) => userRolesList);
export const getUserRolesLoadingSelector = createSelector(getUserRolesLoading,
    (loading) => loading);
export const getUserRolesListCountSelector = createSelector(getUserRolesListCount,
    (userRolesListCount) => userRolesListCount);
export const getSiteAdminSitesSelector = createSelector(getSiteAdminSites,
    (siteAdminSites) => siteAdminSites);
