import axios from 'axios';

export const fetchRegions = (limit: number | null, skip: number, sort: string, search?: string) =>
  axios({
    method: 'POST',
    url: 'cfr-service/region/list',
    data: {
      limit,
      skip,
      sort,
      ...(search ? { searchTerm: search } : {})
    }
  });

export const getFileURL = () => axios({
  method: 'POST',
  url: '/get-dump-url'
});

export const getDashboardURL = (data: { username: string }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/region/dashboard-url',
    data
  });

