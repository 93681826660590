import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import moment from 'moment';

import APPCONSTANTS from '../../constants/appConstants';
import styles from '../../containers/report/ReportList.module.scss';

interface IDatePickerProps {
  pickerRef?: React.LegacyRef<DatePicker>;
  dayReducer?: number;
  onDateSelect?: (date: Date) => void;
  showYearPicker?: boolean;
  showMonthYearPicker?: boolean;
}

const CustomDatePicker = ({
    pickerRef, dayReducer, onDateSelect, showYearPicker, showMonthYearPicker
  }: IDatePickerProps): React.ReactElement  => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const range = (start: number, end: number) => {
    return new Array(end - start).fill('').map((date, i) => i + start).reverse();
  };
  const years = range(getYear(moment(APPCONSTANTS.MIN_DATE).toDate()), getYear(new Date()) + 1);
  const months = APPCONSTANTS.MONTH.map((month) => month.name);

    if (showYearPicker) {
      return (
      <DatePicker
      selected={startDate}
      onChange={(date: Date) => {
        setStartDate(date);
        onDateSelect?.(date);
      }}
      isClearable={true}
      dateFormat='yyyy'
      maxDate={moment().subtract(dayReducer, 'month').toDate()}
      showDisabledMonthNavigation={true}
      showYearPicker={true}
      showMonthYearPicker={false}
      ref={pickerRef}
    />);
    }
    else if ( showMonthYearPicker ) {

      return (
      <DatePicker
        renderCustomHeader={() => <b>Months</b>}
        selected={startDate}
        onChange={(date: Date) => {
          setStartDate(date);
          onDateSelect?.(date);
        }}
        isClearable={true}
        dateFormat='MMM'
        showDisabledMonthNavigation={true}
        showYearPicker={false}
        showMonthYearPicker={true}
        showTwoColumnMonthYearPicker={true}
        ref={pickerRef}
      />
      );
    } else {
      return (
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <div className={styles.quickControls}>
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {'<'}
            </button>
            <div
              className={`nav-link dropdown-toggle light d-flex align-items-center`}
              id='yearScrollingDropdown'
              role='button'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >{getYear(date)}</div>
            <ul
              className={`dropdown-menu dropdown-menu-end highlight-small-text pt-0 ${styles.navbarDropdown}`}
              aria-labelledby='yearScrollingDropdown'
            >
              {years.map(option => (
                <li key={option} value={option} onClick={() => changeYear(option)}>
                  <div className={`dropdown-item pointer ${styles.navbarDropdownItem}`}>
                    {option}
                  </div>
                </li>
              ))}
            </ul>
            <div
              className={`nav-link dropdown-toggle light d-flex align-items-center`}
              id='monthScrollingDropdown'
              role='button'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >{months[getMonth(date)]}</div>
            <ul
              className={`dropdown-menu dropdown-menu-end highlight-small-text pt-0 ${styles.navbarDropdown}`}
              aria-labelledby='monthScrollingDropdown'
            >
              {months.map(option => (
                <li key={option} value={option} onClick={() => (changeMonth(months.indexOf(option)))}>
                  <div className={`dropdown-item pointer ${styles.navbarDropdownItem}`}>
                    {option}
                  </div>
                </li>
              ))}
            </ul>
            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {'>'}
            </button>
          </div>
        )}
        selected={startDate}
        onChange={(date: Date) => {
          setStartDate(date);
          onDateSelect?.(date);
        }}
        isClearable={true}
        dateFormat={APPCONSTANTS.DATE_PICKER_FORMAT}
        maxDate={moment().subtract(dayReducer, 'day').toDate()}
        minDate={moment(APPCONSTANTS.MIN_DATE).toDate()}
        showDisabledMonthNavigation={true}
        showYearPicker={false}
        showMonthYearPicker={false}
        ref={pickerRef}
        />
        );
    }
};

export default CustomDatePicker;
