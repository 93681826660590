export const FETCH_USER_ROLES_LIST_REQUEST = 'FETCH_USER_ROLES_LIST_REQUEST';
export const FETCH_USER_ROLES_LIST_SUCCESS = 'FETCH_USER_ROLES_LIST_SUCCESS';
export const FETCH_USER_ROLES_LIST_FAILURE = 'FETCH_USER_ROLES_LIST_FAILURE';

export const CHANGE_ADMIN_USER_ROLE_REQUEST = 'CHANGE_ADMIN_USER_ROLE_REQUEST';
export const CHANGE_ADMIN_USER_ROLE_SUCCESS = 'CHANGE_ADMIN_USER_ROLE_SUCCESS';
export const CHANGE_ADMIN_USER_ROLE_FAILURE = 'CHANGE_ADMIN_USER_ROLE_FAILURE';

export const FETCH_SITE_ADMIN_SITES_REQUEST = 'FETCH_SITE_ADMIN_SITES_REQUEST';
export const FETCH_SITE_ADMIN_SITES_SUCCESS = 'FETCH_SITE_ADMIN_SITES_SUCCESS';
export const FETCH_SITE_ADMIN_SITES_FAILURE = 'FETCH_SITE_ADMIN_SITES_FAILURE';
