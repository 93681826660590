import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import { PROTECTED_ROUTES, PUBLIC_ROUTES } from './constants/route';
import Region from './containers/region/Region';
import SiteList from './containers/site/SiteList';
import ReportList from './containers/report/ReportList';
import MOHReport from './containers/report/MOHReport';
import Login from './containers/authentication/Login';
import ResetPassword from './containers/authentication/ResetPassword';
import ForgotPassword from './containers/authentication/ForgotPassword';
import { getIsLoggedInSelector, roleSelector } from './store/user/selectors';
import { AppLayout } from './components/appLayout/AppLayout';
import FileDownloader from './containers/region/FileDownloader';
import PermanentReport from './containers/report/PermanentReport';
import DailyReport from './containers/report/DailyReport';
import ScreenedReport from './containers/report/ScreenedReport';
import EnrolledPatientsReport from './containers/report/EnrolledPatientsReport';
import PrescriptionReport from './containers/report/PrescriptionReport';
import APPCONSTANTS from './constants/appConstants';
import UserRoles from './containers/userRoles/UserRoles';
import AssessmentReport from './containers/report/AssessmentReport';
import Dashboard from './containers/dashboard/Dashboard';
import Home from './containers/home/Home';

interface IRoute {
  path: string;
  exact: boolean;
  component: React.FunctionComponent<any> | React.ComponentClass<any>;
}

interface IRole {
  role: string;
  label: string;
}

interface IProtectedRoute extends IRoute {
  authorisedRoles?: IRole[];
}

const protectedRoutes: IProtectedRoute[] = (() => {
  const {
    SYSTEM_ADMIN,
    EMR_REPORT_ADMIN,
    EMR_SITE_ADMIN,
    EMR_REGION_ADMIN,
    EMR_ACCOUNT_ADMIN,
    EMR_OU_ADMIN,
    EMR_QUICKSIGHT_ADMIN
  } = APPCONSTANTS.ROLES;
  return [
    {
      path: PROTECTED_ROUTES.regionDashboard,
      exact: true,
      component: Region,
      authorisedRoles: [
        SYSTEM_ADMIN,
        EMR_REPORT_ADMIN,
        EMR_SITE_ADMIN,
        EMR_REGION_ADMIN,
        EMR_ACCOUNT_ADMIN,
        EMR_OU_ADMIN
      ]
    },
    {
      path: PROTECTED_ROUTES.siteByRegion,
      exact: true,
      component: SiteList,
      authorisedRoles: [
        SYSTEM_ADMIN,
        EMR_REPORT_ADMIN,
        EMR_SITE_ADMIN,
        EMR_REGION_ADMIN,
        EMR_ACCOUNT_ADMIN,
        EMR_OU_ADMIN
      ]
    },
    {
      path: PROTECTED_ROUTES.reportList,
      exact: true,
      component: ReportList,
      authorisedRoles: [
        SYSTEM_ADMIN,
        EMR_REPORT_ADMIN,
        EMR_SITE_ADMIN,
        EMR_REGION_ADMIN,
        EMR_ACCOUNT_ADMIN,
        EMR_OU_ADMIN
      ]
    },
    {
      path: PROTECTED_ROUTES.MOHReport,
      exact: true,
      component: MOHReport,
      authorisedRoles: [
        SYSTEM_ADMIN,
        EMR_REPORT_ADMIN,
        EMR_SITE_ADMIN,
        EMR_REGION_ADMIN,
        EMR_ACCOUNT_ADMIN,
        EMR_OU_ADMIN
      ]
    },
    {
      path: PROTECTED_ROUTES.permanentReport,
      exact: true,
      component: PermanentReport,
      authorisedRoles: [
        SYSTEM_ADMIN,
        EMR_REPORT_ADMIN,
        EMR_SITE_ADMIN,
        EMR_REGION_ADMIN,
        EMR_ACCOUNT_ADMIN,
        EMR_OU_ADMIN
      ]
    },
    {
      path: PROTECTED_ROUTES.dailyReport,
      exact: true,
      component: DailyReport,
      authorisedRoles: [
        SYSTEM_ADMIN,
        EMR_REPORT_ADMIN,
        EMR_SITE_ADMIN,
        EMR_REGION_ADMIN,
        EMR_ACCOUNT_ADMIN,
        EMR_OU_ADMIN
      ]
    },
    {
      path: PROTECTED_ROUTES.fileDownload,
      exact: true,
      component: FileDownloader,
      authorisedRoles: [SYSTEM_ADMIN]
    },
    {
      path: PROTECTED_ROUTES.screenedReport,
      exact: true,
      component: ScreenedReport,
      authorisedRoles: [
        SYSTEM_ADMIN,
        EMR_REPORT_ADMIN,
        EMR_SITE_ADMIN,
        EMR_REGION_ADMIN,
        EMR_ACCOUNT_ADMIN,
        EMR_OU_ADMIN
      ]
    },
    {
      path: PROTECTED_ROUTES.enrolledPatientReport,
      exact: true,
      component: EnrolledPatientsReport,
      authorisedRoles: [
        SYSTEM_ADMIN,
        EMR_REPORT_ADMIN,
        EMR_SITE_ADMIN,
        EMR_REGION_ADMIN,
        EMR_ACCOUNT_ADMIN,
        EMR_OU_ADMIN
      ]
    },
    {
      path: PROTECTED_ROUTES.prescriptionReport,
      exact: true,
      component: PrescriptionReport,
      authorisedRoles: [
        SYSTEM_ADMIN,
        EMR_REPORT_ADMIN,
        EMR_SITE_ADMIN,
        EMR_REGION_ADMIN,
        EMR_ACCOUNT_ADMIN,
        EMR_OU_ADMIN
      ]
    },
    {
      path: PROTECTED_ROUTES.manageUserRoles,
      exact: true,
      component: UserRoles,
      authorisedRoles: [SYSTEM_ADMIN, EMR_REPORT_ADMIN, EMR_REGION_ADMIN]
    },
    {
      path: PROTECTED_ROUTES.assessmentReport,
      exact: true,
      component: AssessmentReport,
      authorisedRoles: [
        SYSTEM_ADMIN,
        EMR_REPORT_ADMIN,
        EMR_SITE_ADMIN,
        EMR_REGION_ADMIN,
        EMR_ACCOUNT_ADMIN,
        EMR_OU_ADMIN
      ]
    },
    {
      path: PROTECTED_ROUTES.home,
      exact: true,
      component: Home,
      authorisedRoles: [
        SYSTEM_ADMIN,
        EMR_REPORT_ADMIN,
        EMR_SITE_ADMIN,
        EMR_REGION_ADMIN,
        EMR_ACCOUNT_ADMIN,
        EMR_OU_ADMIN,
        EMR_QUICKSIGHT_ADMIN
      ]
    },
    {
      path: PROTECTED_ROUTES.quickSightDashboard,
      exact: true,
      component: Dashboard,
      authorisedRoles: [
        SYSTEM_ADMIN,
        EMR_REPORT_ADMIN,
        EMR_SITE_ADMIN,
        EMR_REGION_ADMIN,
        EMR_ACCOUNT_ADMIN,
        EMR_OU_ADMIN,
        EMR_QUICKSIGHT_ADMIN
      ]
    }
  ];
})();

const publicRoutes = [
  {
    path: PUBLIC_ROUTES.login,
    exact: true,
    component: Login
  },
  {
    path: PUBLIC_ROUTES.forgotPassword,
    exact: true,
    component: ForgotPassword
  },
  {
    path: PUBLIC_ROUTES.resetPassword,
    exact: true,
    component: ResetPassword
  }
];

export const AppRoutes = () => {
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const role = useSelector(roleSelector);
  return isLoggedIn ? (
    <AppLayout>
      <Switch>
        {protectedRoutes.map((route: any, index: number) =>
          route.authorisedRoles?.includes(role) ? (
            <Route
              path={route.path}
              exact={route.exact}
              key={index}
              render={(routeProps: any) => (
                <route.component key={routeProps.location.key} {...routeProps} />
              )}
            />
          ) : null
        )}
        <Redirect exact={true} to={PROTECTED_ROUTES.home} />
      </Switch>
    </AppLayout>
  ) : (
    <Switch>
      {publicRoutes.map((route: any, index: number) => (
        <Route
          path={route.path}
          exact={route.exact}
          key={index}
          render={(routeProps: any) => (
            <route.component key={routeProps.location.key} {...routeProps} />
          )}
        />
      ))}
      <Redirect exact={true} to={PUBLIC_ROUTES.login} />
    </Switch>
  );
};
