import * as REGION_TYPES from './actionTypes';

import { RegionActions, IRegionState } from './types';

const initialState: IRegionState = {
  regions: [],
  total: 0,
  loading: false,
  loadingMore: false,
  error: null,
  detail: {
    id: 0,
    name: '',
    country_code: '',
    phone_number: '',
    email: '',
    users: [],
    tenant_id: ''
  }
};

const regionReducer = (state = initialState, action: RegionActions): IRegionState => {
  switch (action.type) {
    case REGION_TYPES.FETCH_REGIONS_REQUEST:
      return {
        ...state,
        [action.isLoadMore ? 'loadingMore' : 'loading']: true
      };
    case REGION_TYPES.FETCH_REGIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        regions: action.payload.isLoadMore ? [...state.regions, ...action.payload.regions || []]
          : action.payload.regions || [],
        total: action.payload.isLoadMore ? state.total : action.payload.totalCount,
        error: null
      };
    case REGION_TYPES.FETCH_REGIONS_FAILURE:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        regions: [],
        total: 0
      };
    case REGION_TYPES.FETCH_FILE_URL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case REGION_TYPES.FETCH_FILE_URL_REQUEST_SUCCESS:
    case REGION_TYPES.FETCH_FILE_URL_REQUEST_FAIL:
      return {
        ...state,
        loading: false
      };
    case REGION_TYPES.SET_REGION_DETAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action.payload
        }
      };
    case REGION_TYPES.CLEAR_REGION_DETAIL:
      return {
        ...state,
        detail: initialState.detail
      };
    default:
      return {
        ...state
      };
  }
};

export default regionReducer;
