import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import ERRORS from '../../constants/error';
import APPCONSTANTS from '../../constants/appConstants';
import DetailCard from '../../components/detailCard/DetailCard';
import CustomTable from '../../components/customtable/CustomTable';
import { getSiteListSelector, getSiteLoadingSelector, getSiteListTotalSelector } from '../../store/site/selectors';
import { fetchSiteListRequest, setSiteDetail } from '../../store/site/actions';
import toastCenter from '../../utils/toastCenter';
import Loader from '../../components/loader/Loader';
import { PROTECTED_ROUTES } from '../../constants/route';
import sessionStorageServices from '../../global/sessionStorageServices';

interface IMatchParams {
  regionId: string;
}

const SiteList = (props: IMatchParams): React.ReactElement => {
  const [listParams, setListReqParams] = useState({
    page: 1,
    rowsPerPage: APPCONSTANTS.ROWS_PER_PAGE_OF_TABLE,
    searchTerm: ''
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const sites = useSelector(getSiteListSelector);
  const loading = useSelector(getSiteLoadingSelector);
  const siteCount = useSelector(getSiteListTotalSelector);

  const { regionId } = useParams<IMatchParams>();

  useEffect(() => {
    dispatch(
      fetchSiteListRequest({
        regionId: Number(regionId),
        skip: (listParams.page - 1) * listParams.rowsPerPage,
        limit: listParams.rowsPerPage,
        search: listParams.searchTerm,
        failureCb: (e) => {
          if (e.message === ERRORS.NETWORK_ERROR.message) {
            toastCenter.error(APPCONSTANTS.NETWORK_ERROR, APPCONSTANTS.CONNECTION_LOST);
          } else {
            toastCenter.error(APPCONSTANTS.OOPS, APPCONSTANTS.SITE_FETCH_ERROR);
          }
        }
      })
    );
  }, [listParams, regionId, dispatch]);

  const handleSearch = (searchString: string) => {
    setListReqParams((prevstate) => {
      return {
        ...prevstate,
        page: 1,
        searchTerm: searchString
      };
    });
  };

  const handlePage = (pageNo: number, rowsPerPage: number | undefined = APPCONSTANTS.ROWS_PER_PAGE_OF_TABLE) => {
    setListReqParams((prevstate) => {
      return {
        ...prevstate,
        page: pageNo,
        rowsPerPage
      };
    });
  };

  const handleRowClick = (data: any) => {
    sessionStorageServices.setItem(APPCONSTANTS.SITE_TENANT_ID, data.tenantId);
    dispatch(setSiteDetail({ name: data.name, id: data.id }));
    history.push(PROTECTED_ROUTES.reportList.replace(':siteId', data.id).replace(':siteName',
     data.name.replace(/\//g, '')).replace(':tenantId', data.tenantId));
  };

  return (
    <>
      {loading && <Loader />}
      <div className='col-12'>
        <DetailCard
          header='Sites'
          isSearch={true}
          onSearch={handleSearch}
        >
          <CustomTable
            rowData={sites}
            columnsDef={[
              {
                id: 1,
                name: 'name',
                label: 'Name'
              },
              {
                id: 2,
                name: 'city',
                label: 'City/Town'
              },
              {
                id: 3,
                name: 'phoneNumber',
                label: 'Phone Number',
                class: 'numeric'
              }
            ]}
            isDelete={false}
            isEdit={false}
            isView={true}
            isDownload={false}
            page={listParams.page}
            rowsPerPage={listParams.rowsPerPage}
            count={siteCount}
            onRowView={handleRowClick}
            handlePageChange={handlePage}
            handleRowClick={handleRowClick}
          />
        </DetailCard>
      </div>
    </>
  );
};

export default SiteList;
