import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as reportService from '../../services/reportAPI';
import {
  IFetchIScreenedListStateRequest,
  IFetchIEnrolledPatientStateRequest,
  IFetchAssessmentListRequest
} from '../report/types';
import {
  fetchScreenedPatientListSuccess,
  fetchScreenedPatientListFailure,
  fetchEnrolledPatientSuccess,
  fetchEnrolledPatientFailure,
  fetchAssessmentListSuccess,
  fetchAssessmentListFailure
} from './actions';
import {
  FETCH_SCREENED_PATIENT_LIST_REQUEST,
  FETCH_ENTROLLED_PATIENT_REQUEST,
  FETCH_ASSESSMENT_LIST_REQUEST
} from './actionTypes';

/*
  Worker Saga: Fired on FETCH_SCREENED_PATIENT_LIST_REQUEST action
*/
function* fetchScreenedList(action: IFetchIScreenedListStateRequest): SagaIterator {
  try {
    const {
      data: { entityList: screenedList, totalCount, limit }
    } = yield call(reportService.fetchScreenedReport, action.payload);
    const payload = { screenedList, totalCount, limit };
    yield put(fetchScreenedPatientListSuccess(payload));
  } catch (e) {
    if (e instanceof Error) {
      action.payload.failureCb?.(e);
      yield put(fetchScreenedPatientListFailure(e));
    }
  }
}

/*
  Worker Saga: Fired on FETCH_ENTROLLED_PATIENT_REQUEST action
*/
function* fetchEnrolledPatientList(action: IFetchIEnrolledPatientStateRequest): SagaIterator {
  try {
    const {
      data: { entityList: enrolledPatientList, totalCount, limit }
    } = yield call(reportService.fetchEntrolledPatientReport, action.payload);
    const payload = { enrolledPatientList, totalCount, limit };
    yield put(fetchEnrolledPatientSuccess(payload));
  } catch (e) {
    if (e instanceof Error) {
      action.payload.failureCb?.(e);
      yield put(fetchEnrolledPatientFailure(e));
    }
  }
}

/*
  Worker Saga: Fired on FETCH_ASSESSMENT_LIST_REQUEST action
*/
function* fetchAssessmentList(action: IFetchAssessmentListRequest): SagaIterator {
  try {
    const {
      data: { entityList: assessmentList, totalCount, limit }
    } = yield call(reportService.fetchAssessmentListReport, action.payload);
    const payload = { assessmentList, totalCount, limit };
    yield put(fetchAssessmentListSuccess(payload));
  } catch (e) {
    if (e instanceof Error) {
      action.payload.failureCb?.(e);
      yield put(fetchAssessmentListFailure(e));
    }
  }
}

/*
  Starts worker saga on latest dispatched specific action.
  Allows concurrent increments.
*/
function* reportSaga() {
  yield all([takeLatest(FETCH_SCREENED_PATIENT_LIST_REQUEST, fetchScreenedList)]);
  yield all([takeLatest(FETCH_ENTROLLED_PATIENT_REQUEST, fetchEnrolledPatientList)]);
  yield all([takeLatest(FETCH_ASSESSMENT_LIST_REQUEST, fetchAssessmentList)]);
}

export default reportSaga;
