import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router';
import HomeIcon from '../../assets/images/home.svg';
import { PROTECTED_ROUTES } from '../../constants/route';
import { useDispatch, useSelector } from 'react-redux';
import { getRegionDetailSelector } from '../../store/region/selectors';
import { getSiteDetailSelector } from '../../store/site/selectors';

import styles from './Breadcrumb.module.scss';
import { clearRegionDetail } from '../../store/region/actions';
import sessionStorageServices from '../../global/sessionStorageServices';
import APPCONSTANTS from '../../constants/appConstants';
import { clearIsKenya, fetchCountryName } from '../../store/user/actions';

export interface ICustomBreadcrumb {
  label: string;
  route: string;
  active: boolean;
}

const defaultBreadcrumbs: ICustomBreadcrumb[] = [
  { route: PROTECTED_ROUTES.manageUserRoles, label: 'Manage User Roles', active: true }
];

const classNameStyle = `fs-1dot5 fw-bold no-pointer-events ${styles.active}`;
/**
 * Dynamic breadcrumb for application
 * @returns {React.ReactElement}
 */
const Breadcrumb = ({ custom }: { custom?: ICustomBreadcrumb[] }): React.ReactElement => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const region = useSelector(getRegionDetailSelector);
  const site = useSelector(getSiteDetailSelector);
  const countryId = sessionStorageServices.getItem(APPCONSTANTS.REGION_ID);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const customBreadcrumb = custom?.length ? custom : [];

  const activeRoute = useMemo(
    () =>
      Object.values(PROTECTED_ROUTES).find((route) => Boolean(matchPath(pathname, { path: route, exact: true }))) || '',
    [pathname]
  );

  const handleHome = () => {
    dispatch(clearRegionDetail());
    dispatch(clearIsKenya());
    sessionStorageServices.deleteItem(APPCONSTANTS.IS_KENYA);
    dispatch(fetchCountryName());
  };

  const defaultBreadcrumb = defaultBreadcrumbs.find(({ route }) =>
    Boolean(matchPath(pathname, { path: route, exact: true })));

  const activeRouteConditions = (result: any, isMOHReportPage: boolean, isPermanentReportPage: boolean,
    isDailyReportPage: boolean, isScreenedReportPage: boolean, isEnrolledPatientReport: boolean,
    isAssessmentReport: boolean, isPrescriptionReport: boolean) => {
    if (isMOHReportPage) {
      result.push({
        label: 'MOH 740 Reports',
        route: PROTECTED_ROUTES.reportList.replace(':siteId', site.id.toString())
          .replace(':siteName', site.name.replace(/\//g, '')),
        active: activeRoute.includes(':siteId')
      });
    } else if (isPermanentReportPage) {
      result.push({
        label: 'Permanent Register',
        route: PROTECTED_ROUTES.reportList.replace(':siteId', site.id.toString())
          .replace(':siteName', site.name.replace(/\//g, '')),
        active: activeRoute.includes(':siteId')
      });
    } else if (isDailyReportPage) {
      result.push({
        label: 'Daily Register',
        route: PROTECTED_ROUTES.reportList.replace(':siteId', site.id.toString())
          .replace(':siteName', site.name.replace(/\//g, '')),
        active: activeRoute.includes(':siteId')
      });
    } else if (isScreenedReportPage) {
      result.push({
        label: 'Screened Patients',
        route: PROTECTED_ROUTES.reportList.replace(':siteId', site.id.toString())
          .replace(':siteName', site.name.replace(/\//g, '')),
        active: activeRoute.includes(':siteId')
      });
    } else if (isEnrolledPatientReport) {
      result.push({
        label: 'Enrolled Patients',
        route: PROTECTED_ROUTES.reportList.replace(':siteId', site.id.toString())
          .replace(':siteName', site.name.replace(/\//g, '')),
        active: activeRoute.includes(':siteId')
      });
    } else if (isAssessmentReport) {
      result.push({
        label: 'Assessment Report',
        route: PROTECTED_ROUTES.reportList.replace(':siteId', site.id.toString())
          .replace(':siteName', site.name.replace(/\//g, '')),
        active: activeRoute.includes(':siteId')
      });
    }  else if (isPrescriptionReport) {
      result.push({
        label: 'Prescription Medication',
        route: PROTECTED_ROUTES.reportList.replace(':siteId', site.id.toString())
          .replace(':siteName', site.name.replace(/\//g, '')),
        active: activeRoute.includes(':siteId')
      });
    }
  };


  const regionDashboardFunction = (result: any, customSections: ICustomBreadcrumb[], showRegion: boolean) => {
    if (Boolean(matchPath(pathname, { path: PROTECTED_ROUTES.regionDashboard, exact: true }))
      || (region?.name && showRegion)) {
      result.push({
        label: 'Regions',
        route: PROTECTED_ROUTES.regionDashboard,
        active: activeRoute.includes('region') && !activeRoute.includes(':regionId')
      });
    } else if (matchPath(pathname, { path: PROTECTED_ROUTES.quickSightDashboard, exact: true })) {
      result.push({
        label: 'Insights',
        route: PROTECTED_ROUTES.regionDashboard,
        active: activeRoute.includes(PROTECTED_ROUTES.quickSightDashboard)
      });
    } else if (countryId !== 'undefined' && countryId) {
      result.push({
        label: 'Sites',
        route: PROTECTED_ROUTES.siteByRegion.replace(':regionId', countryId),
        active: activeRoute.includes(':regionId') && !customSections.length
      });
    }
    return result;
  };

  const sections = useMemo(
    (customSections: ICustomBreadcrumb[] = customBreadcrumb) => {
      const result: ICustomBreadcrumb[] = [];
      const showSite = activeRoute.includes(':siteId');
      const showRegion = showSite || activeRoute.includes(':regionId');
      // we have a default breadcrumb for certain routes
      if (defaultBreadcrumb) {
        // this if block executes when current route is one of defaultBreadcrumbs routes
        result.push(defaultBreadcrumb);
        return result;
      }
      regionDashboardFunction(result, customSections, showRegion);
      if (region?.name && showRegion) {
        result.push({
          label: region.name,
          route: PROTECTED_ROUTES.siteByRegion.replace(':regionId', region.id.toString()),
          active: activeRoute.includes(':regionId') && !customSections.length
        });
      }
      if (site?.name.replace(/[/\s]/g, '') && showSite) {
        const isMOHReportPage = Boolean(matchPath(pathname, { path: PROTECTED_ROUTES.MOHReport, exact: true }));
        const isPermanentReportPage = Boolean(matchPath(pathname, {
          path: PROTECTED_ROUTES.permanentReport, exact: true
        }));
        const isDailyReportPage = Boolean(matchPath(pathname, { path: PROTECTED_ROUTES.dailyReport, exact: true }));
        const isScreenedReportPage = Boolean(matchPath(pathname,
          { path: PROTECTED_ROUTES.screenedReport, exact: true }));
        const isEnrolledPatientReport = Boolean(matchPath(pathname,
          { path: PROTECTED_ROUTES.enrolledPatientReport, exact: true }));
        const isAssessmentReport = Boolean(matchPath(pathname,
          { path: PROTECTED_ROUTES.assessmentReport, exact: true }));
        const isPrescriptionReport = Boolean(matchPath(pathname,
          { path: PROTECTED_ROUTES.prescriptionReport, exact: true }));
        result.push({
          label: site.name,
          route: PROTECTED_ROUTES.reportList.replace(':siteId',
            site.id.toString()).replace(':siteName', site.name.replace(/\//g, '')),
          active: !(isMOHReportPage || isPermanentReportPage || isDailyReportPage
            || isScreenedReportPage || isEnrolledPatientReport || isAssessmentReport
            || isPrescriptionReport
          ) && (activeRoute.includes(':siteId'))
        });
        activeRouteConditions(result, isMOHReportPage, isPermanentReportPage,
          isDailyReportPage, isScreenedReportPage, isEnrolledPatientReport, isAssessmentReport, isPrescriptionReport);
      }
      if (customSections?.length) {
        customSections.forEach((section) => {
          result.push({
            label: section.label,
            route: section.route,
            active: section.active
          });
        });
      }
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [region, site, pathname, activeRoute, customBreadcrumb, defaultBreadcrumb, countryId]
  );

  return (
    <div className={`${styles.breadcrumb} d-flex align-items-center`}>
      <Link
        className={`${styles.homeIcon} d-inline-flex align-items-center justify-content-center me-dot75 lh-0`}
        to={PROTECTED_ROUTES.home}
        onClick={() => {
          handleHome();
        }}
      >
        <img className='d-inline-block' src={HomeIcon} alt='Home' />
      </Link>
      <div>
        {sections.length ? (
          sections.map(({ label, route, active }, i) => (
            <React.Fragment key={label}>
              {!!i && <span className='subtle-color mx-dot25 align-baseline'>/</span>}
              <Link
                to={route}
                className={`align-baseline ${styles.breadcrumbLink} ${active ? classNameStyle : ''
                  }`}
              >
                {label}
              </Link>
            </React.Fragment>
          ))
        ) : (
          <span className='fs-1dot5 primary-title fw-bold'>Home</span>
        )}
      </div>
    </div>
  );
};

export default Breadcrumb;
