import axios from 'axios';
import { IChangeAdminUserRoleRequestPayload, IFetchSiteAdminSitesRequest, IFetchUserRolesListRequest } from '../store/userRoles/types';

export const fetchUserRolesList = (parms: IFetchUserRolesListRequest) =>
  axios({
    method: 'POST',
    url: 'user-service/user/user-list',
    data: {
      limit: parms.limit,
      skip: parms.skip,
      searchTerm: parms?.search || '',
      countryId: parms.country
    }
  });

export const changeAdminUserRole = (data: IChangeAdminUserRoleRequestPayload) =>
  axios({
    method: 'POST',
    url: 'user-service/user/assign-role',
    data
  });

export const fetchSiteAdminSites = ({ id }: IFetchSiteAdminSitesRequest) =>
  axios({
    method: 'POST',
    url: 'user-service/user/site-list',
    data: {
      id
    }
  });
