import { createSelector } from 'reselect';

import { AppState } from '../rootReducer';

const getIsLoggedIn = (state: AppState) => state.user.isLoggedIn;
const getIsLoggingIn = (state: AppState) => state.user.loggingIn;
const getIsLoggingOut = (state: AppState) => state.user.loggingOut;
const getFirstName = (state: AppState) => state.user.user.firstName;
const getLastName = (state: AppState) => state.user.user.lastName;
const getRole = (state: AppState) => state.user.user.role;
const getRoles = (state: AppState) => state.user.user.roles;
const getTimezoneList = (state: AppState) => state.user.timezoneList;
const getLoading = (state: AppState) => state.user.loading;
const getInitializing = (state: AppState) => state.user.initializing;
const getCountryName = (state: AppState) => state.user.user.country;
const getCountry = (state: AppState) => state.user.countryName;
const getIsKenya = (state: AppState) => state.user.isKenya;
const getCountryId = (state: AppState) => state.user.user.country;
const getUsername = (state: AppState) => state.user.user.username;


export const getIsLoggedInSelector = createSelector(getIsLoggedIn, (isLoggedIn) => isLoggedIn);

export const getIsLoggingInSelector = createSelector(getIsLoggingIn, (loggingIn) => loggingIn);

export const getIsLoggingOutSelector = createSelector(getIsLoggingOut, (loggingOut) => loggingOut);

export const firstNameSelector = createSelector(getFirstName, (firstName) => firstName);

export const lastNameSelector = createSelector(getLastName, (lastName) => lastName);

export const timezoneListSelector = createSelector(getTimezoneList, (timezoneList) => timezoneList);

export const loadingSelector = createSelector(getLoading, (loading) => loading);

export const initializingSelector = createSelector(getInitializing, (initializing) => initializing);

export const roleSelector = createSelector(getRole, (role) => role);

export const rolesSelector = createSelector(getRoles, (roles) => roles);

export const countrySelector = createSelector(getCountryName, (country) => country);

export const kenyaSelector = createSelector(getIsKenya, (isKenya) => isKenya);

export const countryNameSelector = createSelector(getCountry, (country) => country);

export const countryIdSelector = createSelector(getCountryId, (countryId) => countryId);

export const usernameSelector = createSelector(getUsername, (username) => username);
