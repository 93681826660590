import * as REPORT_TYPES from './actionTypes';
import {
  IFetchIScreenedListStateFailure,
  IFetchIScreenedListStateRequest,
  IFetchIScreenedListStateSuccess,
  IFetchScreenedListSuccessPayload,
  IFetchIEnrolledPatientStateSuccess,
  IFetchIEnrolledPatientStateRequest,
  IFetchIEnrolledPatientStateFailure,
  IFetchEnrolledPatientSuccessPayload,
  IFetchAssessmentListRequest,
  IFetchAssessmentListSuccessPayload,
  IFetchAssessmentListSuccess,
  IFetchAssessmentListFailure
} from '../report/types';

export const fetchScreenedPatientListRequest = ({
  payload
}: Omit<IFetchIScreenedListStateRequest, 'type'>): IFetchIScreenedListStateRequest => ({
  type: REPORT_TYPES.FETCH_SCREENED_PATIENT_LIST_REQUEST,
  payload
});

export const fetchScreenedPatientListSuccess = (payload: IFetchScreenedListSuccessPayload):
  IFetchIScreenedListStateSuccess => ({
  type: REPORT_TYPES.FETCH_SCREENED_PATIENT_SUCCESS,
  payload
});

export const fetchScreenedPatientListFailure = (error: Error): IFetchIScreenedListStateFailure => ({
  type: REPORT_TYPES.FETCH_SCREENED_PATIENT_FAILURE,
  error
});

export const fetchEnrolledPatientRequest = ({
  payload
}: Omit<IFetchIEnrolledPatientStateRequest, 'type'>): IFetchIEnrolledPatientStateRequest => ({
  type: REPORT_TYPES.FETCH_ENTROLLED_PATIENT_REQUEST,
  payload
});

export const fetchEnrolledPatientSuccess = (payload: IFetchEnrolledPatientSuccessPayload):
  IFetchIEnrolledPatientStateSuccess => ({
  type: REPORT_TYPES.FETCH_ENTROLLED_PATIENT_SUCCESS,
  payload
});

export const fetchEnrolledPatientFailure = (error: Error): IFetchIEnrolledPatientStateFailure => ({
  type: REPORT_TYPES.FETCH_ENTROLLED_PATIENT_FAILURE,
  error
});

export const fetchAssessmentListRequest = ({
  payload
}: Omit<IFetchAssessmentListRequest, 'type'>): IFetchAssessmentListRequest => ({
  type: REPORT_TYPES.FETCH_ASSESSMENT_LIST_REQUEST,
  payload
});

export const fetchAssessmentListSuccess = (payload: IFetchAssessmentListSuccessPayload):
  IFetchAssessmentListSuccess => ({
  type: REPORT_TYPES.FETCH_ASSESSMENT_LIST_SUCCESS,
  payload
});

export const fetchAssessmentListFailure = (error: Error): IFetchAssessmentListFailure => ({
  type: REPORT_TYPES.FETCH_ASSESSMENT_LIST_FAILURE,
  error
});

