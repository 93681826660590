import * as REGION_TYPES from './actionTypes';
import {
  IFetchRegionsRequest,
  IFetchRegionsSuccess,
  IFetchRegionsFailure,
  IFetchRegionsSuccessPayload,
  IFetchFileURL,
  IFetchFileURLSuccess,
  IFetchFileURLFailure,
  IRegionDetail,
  ISetRegionDetail,
  IClearRegionDetail
} from './types';

export const fetchRegionsRequest = ({
  skip,
  limit,
  isLoadMore,
  search,
  successCb,
  failureCb
}: {
  skip: number;
  limit: number | null;
  isLoadMore?: boolean;
  search?: string;
  successCb?: (payload: IFetchRegionsSuccessPayload) => void;
  failureCb?: (error: Error) => void;
}): IFetchRegionsRequest => ({
  type: REGION_TYPES.FETCH_REGIONS_REQUEST,
  skip,
  limit,
  isLoadMore,
  search,
  successCb,
  failureCb
});

export const fetchRegionsSuccess = (payload: IFetchRegionsSuccessPayload): IFetchRegionsSuccess => ({
  type: REGION_TYPES.FETCH_REGIONS_SUCCESS,
  payload
});

export const fetchRegionsFailure = (error: Error): IFetchRegionsFailure => ({
  type: REGION_TYPES.FETCH_REGIONS_FAILURE,
  error
});

export const fetchFileURL = (successCb: ({ url }: { url: string}) => void,
  failureCb: (e: Error) => void): IFetchFileURL => ({
  type: REGION_TYPES.FETCH_FILE_URL_REQUEST,
  successCb,
  failureCb
});

export const fetchFileURLSuccess = (): IFetchFileURLSuccess => ({
  type: REGION_TYPES.FETCH_FILE_URL_REQUEST_SUCCESS
});

export const fetchFileURLFailure = (): IFetchFileURLFailure => ({
  type: REGION_TYPES.FETCH_FILE_URL_REQUEST_FAIL
});

export const setRegionDetail = (payload: Partial<IRegionDetail>): ISetRegionDetail => ({
  type: REGION_TYPES.SET_REGION_DETAIL,
  payload
});

export const clearRegionDetail = (): IClearRegionDetail => ({
  type: REGION_TYPES.CLEAR_REGION_DETAIL
});
