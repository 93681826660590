import { createSelector } from 'reselect';
import { AppState } from '../rootReducer';

const getSite = (state: AppState) => state.site.site;
const getLoading = (state: AppState) => state.site.loading;
const getTotal = (state: AppState) => state.site.total;
const getSiteListDetails = (state: AppState) => state.site.siteList;
const getSiteDetail = (state: AppState) => state.site.detail;

export const siteSelector = createSelector(getSite, (site) => site);
export const getSiteLoadingSelector = createSelector(getLoading, (loading) => loading);
export const getSiteListTotalSelector = createSelector(getTotal, (total) => total);
export const getSiteListSelector = createSelector(getSiteListDetails, (siteList) => siteList);
export const getSiteDetailSelector = createSelector(getSiteDetail, (detail) => detail);
