import { combineReducers } from 'redux';

import userReducer from './user/reducer';
import regionReducer from './region/reducer';
import siteReducer from './site/reducer';
import reportReducer from './report/reducer';
import userRolesReducer from './userRoles/reducer';

const appReducer = combineReducers({
  user: userReducer,
  region: regionReducer,
  site: siteReducer,
  report: reportReducer,
  userRoles: userRolesReducer
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'RESET_STORE') {
    state = undefined;
  }
  return appReducer(state, action);
};
export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
