const APPCONSTANTS = {
  HASH_ALGORITM: 'sha512',
  AUTHTOKEN: 'at',
  USERNAME: 'un',
  PASSWORD: 'pw',
  REMEMBER_ME: 'rm',
  TENANT_ID: 'ti',
  ENCRYPTION: {
    ALGM: 'aes-256-gcm',
    IV: 'kemr@123',
    SALT: 'kemr@123',
    ITERATION: 2048,
    KEYLEN: 32,
    DIGEST: 'sha512'
  },
  ROWS_PER_PAGE_OF_TABLE: 10,
  ROLES: {
    SUPER_USER: { role: 'superuser', label: 'Super User' },
    SUPER_ADMIN: { role: 'SUPER_ADMIN', label: 'Super Admin' },
    SYSTEM_ADMIN: { role: 'SYSTEM_ADMIN', label: 'System Admin' },
    REPORT_ADMIN: { role: 'REPORT_ADMIN', label: 'Report Admin' },
    EMR_REPORT_ADMIN: { role: 'EMR_REPORT_ADMIN', label: 'EMR Report Admin' },
    SITE_ADMIN: { role: 'SITE_ADMIN', label: 'Site Admin' },
    EMR_SITE_ADMIN: { role: 'EMR_SITE_ADMIN', label: 'EMR Site Admin' },
    REGION_ADMIN: { role: 'REGION_ADMIN', label: 'Region Admin' },
    EMR_REGION_ADMIN: { role: 'EMR_REGION_ADMIN', label: 'EMR Region Admin' },
    ACCOUNT_ADMIN: { role: 'ACCOUNT_ADMIN', label: 'Account Admin' },
    EMR_ACCOUNT_ADMIN: { role: 'EMR_ACCOUNT_ADMIN', label: 'EMR Account Admin' },
    OU_ADMIN: { role: 'OU_ADMIN', label: 'OU Admin' },
    EMR_OU_ADMIN: { role: 'EMR_OPERATING_UNIT_ADMIN', label: 'EMR OU Admin' },
    EMR_QUICKSIGHT_ADMIN: { role: 'EMR_QUICKSIGHT_ADMIN', label: 'EMR Quicksight Admin' }
  },
  APPLICATION_NAME: {
    COUNTRY: { NAME: 'country', LABEl: 'Region' },
    ACCOUNT: { NAME: 'account', LABEL: 'Account' },
    OPERATING_UNIT: { NAME: 'operatingunit', LABEL: 'Operating unit' },
    SITE: { NAME: 'site', LABEL: 'Site' }
  },
  MONTH: [{ name: 'January', label: 'Jan' }, { name: 'February', label: 'Feb' },
  { name: 'March', label: 'Mar' }, { name: 'April', label: 'Apr' }, { name: 'May', label: 'May' },
  { name: 'June', label: 'Jun' }, { name: 'July', label: 'Jul' }, { name: 'August', label: 'Aug' },
  { name: 'September', label: 'Sep' }, { name: 'October', label: 'Oct' },
  { name: 'November', label: 'Nov' }, { name: 'December', label: 'Dec' }],
  LINK_EXPIRED: 'Link has expired.',
  PASSWORD_ALREADY_CHANGED: 'Password already changed.',
  ACCOUNT_ALREADY_ACTIVATED: 'Your account has been already activated.',
  REPORT_UPLOAD_SUCCESS: 'The report has been sent successfully',
  REPORT_PENDING: 'Report Pending',
  SEND_TO_KHIS: 'Send to KHIS',
  CANNOT_SEND_TO_KHIS: `Report can't be sent for previous months`,
  KHIS_LAST_DATE_TO_SUBMIT: 14,
  ALERT: 'Alert',
  PASSWORD_SET_SUCCESS: 'Password has been set successfully.',
  PASSWORD_SET_FAILED: 'Failed to set password. Please try again.',
  SUCCESS: 'Success',
  WARNING: 'Warning',
  PASSWORD_RESET_EMAIL_SENT_MESSAGE:
    'You will receive the email notification to reset the password if your account exists in our system.',
  OOPS: 'Oops',
  ERROR: 'Error',
  NETWORK_ERROR: 'Network Error',
  LOGIN_FAILED: 'Login failed',
  CONNECTION_LOST: 'There is an issue with the connection. Please try after sometime.',
  PASSWORD_RULE: 'Password must contain 6 or more characters, including uppercase, lowercase and number characters.',
  ENTER_PASSWORD: 'Please enter new password',
  ENTER_CONFIRM_PASSWORD: 'Please enter confirm password',
  PASSWORD_SHOULD_NOT_MATCH_ACC_NAME: `New password should not match the user's account name`,
  COMMON_PASSWORDS_ARE_NOT_ALLOWED: 'Common passwords are not allowed',
  CONFIRM_PASSWORD_SHOULD_MATCH: 'Confirm new password should be match with new password',
  LOGIN_GENERAL_ERROR: 'Unable to login. Please try after sometime.',
  SESSION_EXPIRED: 'Session got expired. Please login again.',
  IS_SIDEMENU_COLLAPSED: 'isSmCollapsed',
  NO_RECORDS_FOUND: 'No records found',
  PAGINATION_RANGE: 5,
  FORM_ID: 'formId',
  REGION_ID: 'id',
  COUNTRY_NAME: 'countryName',
  IS_KENYA: 'ik',
  REGIONS_PER_PAGE: 10,
  REGION_FETCH_ERROR: 'Unable to load regions. Please try after sometime.',
  SITE_FETCH_ERROR: 'Unable to load sites. Please try after sometime.',
  USER_ROLES_LIST_FETCH_ERROR: 'Unable to load user roles list. Please try after sometime.',
  SITE_ADMIN_SITES_FETCH_ERROR: 'Unable to load site list. Please try after sometime.',
  USER_ASSIGN_ROLE_SUCCESS: 'User role updated successfully.',
  USER_QUICKSIGHT_SUCCESS: 'User QuickSight permission updated successfully.',
  USER_ASSIGN_ROLE_ERROR: 'Unable to assign user role. Please try after sometime.',
  USER_QUICKSIGHT_ERROR: 'Unable to assign user QuickSight permission. Please try after sometime.',
  SCREENED_PATIENTS_FETCH_ERROR: 'Unable to load screened patients. Please try after sometime.',
  ENROLLED_PATIENTS_FETCH_ERROR: 'Unable to load enrolled patients. Please try after sometime.',
  ASSESSMENT_LIST_FETCH_ERROR: 'Unable to load assessment list. Please try after sometime.',
  PRESCRIPTION_REPORT_FETCH_ERROR: 'Unable to load prescription list. Please try after sometime.',
  SITE_USERS_FETCH_ERROR: 'Unable to load site users. Please try after sometime.',
  SITE_SUMMARY_FETCH_ERROR: 'Unable to load site summary Details. Please try after sometime.',
  EMR_REPORT_ADMIN: 'EMR_REPORT_ADMIN',
  UNAUTHORIZED_ACCESS_MESSAGE: 'You don\'t have permission to perform this operation.',
  DOWNLOAD_FAIL_MSG: 'Unable to download the file. Please try after sometime.',
  DOWNLOAD_NOT_READY: 'Please try after sometime.',
  URL_NOT_FOUND: 'NotFound',
  REPORT_VIEW_FAIL: 'Unable to view the file. Please try after sometime.',
  REPORT_DOWNLOAD_SUCCESS: 'Report downloaded successfully',
  REPORT_NOT_FOUND: 'Report not found.',
  REPORT_NOT_FOUND_DATE: 'Report not found on selected date.',
  REPORT_NOT_FOUND_MONTH: 'Report not found on selected month.',
  GENDER: [{ name: 'Male', label: 'Male' }, { name: 'Female', label: 'Female' }],
  YEAR_MONTH_DATE_FORMAT: 'YYYY-MM-DD',
  DATE_MONTH_YEAR_FORMAT: 'DD/MM/YYYY',
  DATE_PICKER_FORMAT: 'dd/MM/yyyy',
  MIN_DATE: '2017-01-01',
  NUMBER: {
    ZERO: 0,
    ONE: 1,
    TWELVE: 12
  },
  SITE_TENANT_ID: 'sti',
  SEND_TO_KHIS_ROLES_ACCESS: ['EMR_SITE_ADMIN'],
  MANAGE_USER_ROLES_ACCESS: ['SYSTEM_ADMIN', 'EMR_REPORT_ADMIN', 'EMR_REGION_ADMIN'],
  KENYA: 'Kenya',
  KENYA_ID: '4',
  DASHBOARS_FAIL_MSG: 'You are not authenticated for dashboard',
  SONARQUEBE_PASSWORD: 'admin1',
  LOGIN_FAILED_TITLE: 'Login failed'
};

export default APPCONSTANTS;
