import * as USER_ROLES_ACTION_TYPES from './actionTypes';
import {
  IFetchUserRolesListFailure,
  IFetchUserRolesListRequest,
  IFetchUserRolesListSuccess,
  IFetchUserRolesListSuccessPayload,
  IChangeAdminUserRoleFailure,
  IChangeAdminUserRoleRequest,
  IChangeAdminUserRoleSuccess,
  IFetchSiteAdminSitesFailure,
  IFetchSiteAdminSitesRequest,
  IFetchSiteAdminSitesSuccess,
  IFetchSiteAdminSitesSuccessPayload
} from './types';

export const fetchUserRolesListRequest = ({
  skip,
  limit,
  search,
  country,
  failureCb
}: {
  skip: number;
  limit: number | null;
  search?: string;
  country?: number;
  failureCb?: (error: Error) => void;
}): IFetchUserRolesListRequest => ({
  type: USER_ROLES_ACTION_TYPES.FETCH_USER_ROLES_LIST_REQUEST,
  skip,
  limit,
  search,
  country,
  failureCb
});

export const fetchUserRolesListSuccess = (payload: IFetchUserRolesListSuccessPayload): IFetchUserRolesListSuccess => ({
  type: USER_ROLES_ACTION_TYPES.FETCH_USER_ROLES_LIST_SUCCESS,
  payload
});

export const fetchUserRolesListFailure = (error: Error): IFetchUserRolesListFailure => ({
  type: USER_ROLES_ACTION_TYPES.FETCH_USER_ROLES_LIST_FAILURE,
  error
});


export const changeAdminUserRoleRequest = ({
  data,
  successCb,
  failureCb
}: Omit<IChangeAdminUserRoleRequest, 'type'>): IChangeAdminUserRoleRequest => ({
  type: USER_ROLES_ACTION_TYPES.CHANGE_ADMIN_USER_ROLE_REQUEST,
  data,
  successCb,
  failureCb
});

export const changeAdminUserRoleSuccess = (): IChangeAdminUserRoleSuccess => ({
  type: USER_ROLES_ACTION_TYPES.CHANGE_ADMIN_USER_ROLE_SUCCESS
});

export const changeAdminUserRoleFailure = (error: Error): IChangeAdminUserRoleFailure => ({
  type: USER_ROLES_ACTION_TYPES.CHANGE_ADMIN_USER_ROLE_FAILURE,
  error
});

export const fetchSiteAdminSitesRequest = ({
  id,
  successCb,
  failureCb
}: Omit<IFetchSiteAdminSitesRequest, 'type'>): IFetchSiteAdminSitesRequest => ({
  type: USER_ROLES_ACTION_TYPES.FETCH_SITE_ADMIN_SITES_REQUEST,
  id,
  successCb,
  failureCb
});

export const fetchSiteAdminSitesSuccess = (payload: IFetchSiteAdminSitesSuccessPayload):
  IFetchSiteAdminSitesSuccess => ({
    type: USER_ROLES_ACTION_TYPES.FETCH_SITE_ADMIN_SITES_SUCCESS,
    payload
  });

export const fetchSiteAdminSitesFailure = (error: Error): IFetchSiteAdminSitesFailure => ({
  type: USER_ROLES_ACTION_TYPES.FETCH_SITE_ADMIN_SITES_FAILURE,
  error
});
