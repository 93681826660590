import React from 'react';
import Select from 'react-select';
import './SelectInput.scss';


export interface ISelectOption {
  label: string;
  value: string;
}

export interface ISelectFormOptions {
  label: string;
  key: string;
}

export interface IDefaultValueOption {
  name: string;
  id: string;
}

interface ISelectBoxProps {
  id?: string;
  label: string;
  placeholder?: string;
  error?: string;
  errorLabel?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: any) => void;
  value?: any;
  options: any;
  loadingOptions?: boolean;
  nestedObject?: boolean;
  labelKey?: string | string[];
  valueKey?: string | string[];
  required?: boolean;
  disabled?: boolean;
  loadInputOptions?: (value: string) => Promise<any>;
  appendPlus?: boolean;
  isModel?: boolean;
  isShowLabel?: boolean;
  isMulti?: boolean;
}

export const handleChange = (onChange: (e: any) => void, value: any) => {
  if (onChange) {
    onChange(value);
  }
};

/**
 * Standard select input component for forms
 * wrapped around react-select
 * @param param0
 * @returns {React.ReactElement}
 */
const SelectInput = ({
  id,
  label,
  onFocus,
  onBlur,
  onChange,
  placeholder,
  error = '',
  errorLabel = '',
  options,
  loadingOptions,
  valueKey,
  nestedObject,
  labelKey,
  required = true,
  disabled = false,
  appendPlus = false,
  isModel = false,
  isShowLabel = true,
  isMulti = false,
  value,
  ...rest
}: ISelectBoxProps): React.ReactElement => {
  const getOptionLabel = (option: any) => {
    if (labelKey && nestedObject) {
      return option[labelKey[0]][labelKey[1]];
    } else if (labelKey && typeof labelKey === 'string') {
      return appendPlus ? `+${option[labelKey]}` : option[labelKey];
    } else {
      return option.label;
    }
  };

  const getOptionValue = (option: any) => {
    if (valueKey && nestedObject) {
      return option[valueKey[0]][valueKey[1]];
    } else if (valueKey && typeof valueKey === 'string') {
      return option[valueKey];
    } else {
      return option.value;
    }
  };

  return (
    <div className={'d-flex flex-column selectInputContainer'}>
      {isShowLabel && (
        <label className='mb-0dot5 fs-0dot875 lh-1dot25'>
          {label}
          {required && <span className='input-asterisk'>*</span>}
        </label>
      )}
      <Select
        id={id}
        options={options}
        value={value}
        className={`select-field ${error ? 'danger' : ''}`}
        classNamePrefix='select-field'
        onFocus={onFocus}
        isMulti={isMulti}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder || ''}
        openMenuOnFocus={true}
        isLoading={loadingOptions}
        menuPlacement='auto'
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isDisabled={disabled}
      />
    </div>
  );
};

export default SelectInput;
