import React from 'react';

import IconButton from '../button/IconButton';
import Searchbar from '../searchbar/Searchbar';
import styles from './DetailCard.module.scss';
import SelectInput from '../../components/formFields/SelectInput';

interface IDetailCardProps {
  header: string;
  buttonLabel?: string;
  isEdit?: boolean;
  children: React.ReactElement;
  onSearch?: (searchStr: string) => void;
  isSearch?: boolean;
  onButtonClick?: () => void;
  className?: string;
  isDownload?: boolean;
  searchPlaceholderName?: string;
  isDisabled?: boolean;
  selectBox?: boolean;
  selectBoxProps?: any;
}

/**
 * The component for card detail with searchbar and button.
 * @param param
 * @returns React.ReactElement
 */
const DetailCard = ({
  header,
  buttonLabel,
  isEdit,
  children,
  onSearch,
  isSearch = false,
  onButtonClick,
  className = '',
  isDownload,
  searchPlaceholderName,
  isDisabled = false,
  selectBox = false,
  selectBoxProps
}: IDetailCardProps): React.ReactElement => {
  return (
    <div className={`card ${styles.detail} ${className}`}>
      <div className={`card-header bg-transparent ${styles.header}`}>
        <span className={styles.headerLabel}>{header}</span>
        <div className='d-flex'>
          {isSearch && onSearch && (
            <div className={`${(buttonLabel && onButtonClick) || selectBox ? 'me-1' : ''}`}>
              <Searchbar onSearch={onSearch}
                placeholder={searchPlaceholderName ? searchPlaceholderName : 'Search Name'}
              />
            </div>
          )}
          {buttonLabel && onButtonClick ? (
            <IconButton label={buttonLabel} isEdit={isEdit} handleClick={onButtonClick} isDownload={isDownload}
              isDisabled={isDisabled}/>
          ) : null}
          {selectBox &&
            <SelectInput
              label=''
              valueKey={'id'}
              labelKey='name'
              options={selectBoxProps.options}
              isShowLabel={false}
              loadingOptions={selectBoxProps.loading}
              value={selectBoxProps.value}
              onChange={selectBoxProps.onChange}
            />
          }
        </div>
      </div>
      <div className='card-body p-0'>{children}</div>
    </div>
  );
};

export default DetailCard;
